<template>
  <Squares/>
</template>

<script>
  import Squares from './components/Squares';

  export default {
    name: 'App',
    components: {
      Squares
    }
  };
</script>
